import { useRef, useState } from "react"
import type { Swiper as SwiperDef } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Scrollbar, A11y, Autoplay, Controller, EffectFade } from "swiper/modules"
import cc from "classnames"
import { useMotionValue, animate } from "framer-motion"

import { $auth } from "@stores/auth"
import { useStore } from "@nanostores/react"
import { getPaletteColors } from "@utils/getPaletteColors"

import Container from "@components/Container"
import Link from "@components/Link"
import Image from "@components/Image"
import { ReactComponent as IconEnter } from "@icons/enter.svg"
import SpacingContainer from "@components/SpacingContainer"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import { ReactComponent as StarFull } from "@icons/star-full.svg"
import "./QuoteSlider.scss"

interface Slide {
  media: Domain.Shared.DataTransferObjects.MediaResourceData
  copy: string
  quote_author: string
  creator?: Domain.Shared.DataTransferObjects.UserData[]
}
interface QuoteSliderProps extends Domain.Shared.DataTransferObjects.BlockBaseConfigData {
  mirrored?: boolean
  show_featured_in?: boolean
  slides: Slide[]
  featured_in_logos: Domain.Shared.DataTransferObjects.MediaResourceData[]
  background_color: Domain.Shared.Enums.ColorPaletteEnum
  color_palette: Domain.Shared.Enums.ColorPaletteEnum
}

// this module has two swiper instances, which react to slideChanges of the other one
const QuoteSlider = (props: QuoteSliderProps): JSX.Element => {
  const { margin_top, margin_bottom, padding_top, padding_bottom, background_color } = props
  const spacingMarginTop = margin_top?.value ?? "lg"
  const spacingMarginBottom = margin_bottom?.value ?? "none"
  const spacingPaddingTop = padding_top?.value ?? "none"
  const spacingPaddingBottom = padding_bottom?.value ?? "none"

  const palette = getPaletteColors(props.color_palette ?? "spinach")

  const slidesRef = useRef<HTMLDivElement[] | null[]>([])
  const profileLinkRef = useRef<HTMLDivElement[] | null[]>([])
  const [hadUserInteraction, setHadUserInteraction] = useState(false)
  const slideDuration = 5000
  const width = useMotionValue(0)
  const [firstSwiper, setFirstSwiper] = useState<SwiperDef | null>(null)
  const [secondSwiper, setSecondSwiper] = useState<SwiperDef | null>(null)
  // swiper.js can't render a react element, it has to be a plain string for the pagination bullets
  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return `<span class="${className}"><span class="swiper-pagination-bullet-inner swiper-pagination-bullet-inner-${index}"></span></span>`
    },
  }

  const { user } = useStore($auth)
  const isHidden = (props.hide_for_logged_in_users && user) || (props.hide_for_logged_out_users && !user)

  if (isHidden) return <></>

  return (
    <SpacingContainer
      margin_top={spacingMarginTop}
      margin_bottom={spacingMarginBottom}
      padding_top={spacingPaddingTop}
      padding_bottom={spacingPaddingBottom}
      background_color={background_color}
      class_name="block-quote-slider"
    >
      <Container>
        <div className="grid grid-flow-row-dense grid-cols-24 overflow-hidden rounded-8">
          <div className={cc("relative col-span-24 md:col-span-12", { "md:col-start-13": props.mirrored })}>
            <Swiper
              autoplay={{ delay: slideDuration }}
              modules={[Pagination, Scrollbar, A11y, Autoplay, Controller]}
              pagination={pagination}
              watchSlidesProgress={true}
              controller={{ control: secondSwiper }}
              onSwiper={() => setFirstSwiper}
              onSliderFirstMove={() => setHadUserInteraction(true)}
              onSlideChange={(swiper) => {
                // stop a running animation
                width.stop()
                // reset the width to 0
                width.set(0)
                const allPaginationInnerElements = swiper.pagination.el.querySelectorAll<HTMLElement>(
                  ".swiper-pagination-bullet-inner"
                )
                // reset width to 0% on all inner elements
                Array.from(allPaginationInnerElements).forEach((innerEl) => {
                  innerEl.style.transform = "scaleX(0) translate3d(0, 0, 0)"
                })
                // check if it had a user interaction. if so, autoPlay gets disabled, so we don't want to animate the bar anymore'
                const currentPaginationInner = swiper.pagination.el.querySelector(
                  `.swiper-pagination-bullet-inner-${swiper.realIndex}`
                ) as HTMLElement
                if (!hadUserInteraction) {
                  // animate width of pagination inner bar
                  animate(width, 1, {
                    type: "tween",
                    duration: slideDuration / 1000,
                    onUpdate: (latest) => {
                      currentPaginationInner.style.transform = `scaleX(${latest}) translate3d(0, 0, 0)`
                    },
                  })
                } else {
                  // if it had a user interaction, just set the width to 100%
                  currentPaginationInner.style.transform = "scaleX(1) translate3d(0, 0, 0)"
                }
                profileLinkRef.current.forEach((profileLink) => {
                  if (profileLink) profileLink.style.bottom = "-4.8rem"
                })

                const currentProfileLinkRef = profileLinkRef.current[swiper.realIndex]

                if (currentProfileLinkRef) {
                  currentProfileLinkRef.style.bottom = "1.6rem"
                }
              }}
              onAfterInit={(swiper) => {
                // animate width of pagination inner bar, the onSlideChange event is not fired on init
                const currentPaginationInner = swiper.pagination.el.querySelector(
                  `.swiper-pagination-bullet-inner-0`
                ) as HTMLElement
                animate(width, 1, {
                  type: "tween",
                  duration: slideDuration / 1000,
                  onUpdate: (latest) => {
                    currentPaginationInner.style.transform = `scaleX(${latest}) translate3d(0, 0, 0)`
                  },
                })
              }}
            >
              {props.slides.map((slide, i) => (
                <SwiperSlide key={`${slide.copy}-image-${i}`}>
                  <div className="relative">
                    <div
                      className="absolute left-0 top-0 z-10 h-full w-1/2"
                      onClick={() => {
                        if (firstSwiper) firstSwiper.slidePrev()
                      }}
                    ></div>
                    <div
                      className="absolute right-0 top-0 z-10 h-full w-1/2"
                      onClick={() => {
                        if (firstSwiper) firstSwiper.slideNext()
                      }}
                    ></div>
                    <Image
                      src={slide.media.permalink}
                      alt={slide.media.alt}
                      span={12}
                      span_md={6}
                      width={600}
                      height={750}
                      className="flex w-full"
                      focal_points={slide.media.focus}
                      loading={props.is_above_fold ? "eager" : undefined}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {props.slides.map((slide, i) => (
              <div
                key={`${slide.copy}-profile-link-${i}`}
                className={cc("transition-300 absolute right-16 z-20 rounded-8 bg-white transition-all", {
                  "bottom-16": i === 0,
                  "-bottom-80": i !== 0,
                })}
                ref={(el) => (profileLinkRef.current[i] = el)}
              >
                {slide.creator && slide.creator[0] && slide.creator[0]?.first_name && (
                  <Link user={slide.creator[0]}>
                    <div className="flex items-center p-8">
                      <Image
                        src={slide.creator[0]?.media?.permalink}
                        alt={slide.creator[0]?.media?.alt || slide.creator[0].user_name || "User profile picture"}
                        width={30}
                        height={30}
                        className="rounded-4"
                        loading={props.is_above_fold ? "eager" : undefined}
                      />
                      <span className="ml-10 text-14 font-semibold">{slide.creator[0]?.first_name}'s Profil</span>
                      <div className="ml-4 h-16 w-16">
                        <IconEnter />
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            ))}
          </div>

          <div
            className={cc(
              `col-span-24 md:col-span-12 ${
                palette ? `bg-${palette.mainColor}` : ""
              } aspect-h-14 aspect-w-16 relative z-30 md:aspect-h-9 md:aspect-w-8`,
              {
                "md:col-start-1": props.mirrored,
              }
            )}
          >
            <div className="h-full w-full">
              <Swiper
                onSwiper={setSecondSwiper}
                onSliderFirstMove={() => setHadUserInteraction(true)}
                watchSlidesProgress={true}
                modules={[Controller, EffectFade]}
                effect={"fade"}
                className="h-full w-full"
                controller={{ control: firstSwiper }}
              >
                {props.slides.map((slide, i) => (
                  <SwiperSlide key={`${slide.copy}-content-${i}`} className="relative">
                    <div
                      ref={(el) => (slidesRef.current[i] = el)}
                      className={cc(
                        `${palette ? `bg-${palette.mainColor}` : ""} ${
                          palette ? `text-${palette.mainTextColor}` : ""
                        } absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center px-16 text-center md:px-56`
                      )}
                    >
                      <div className="mb-24 flex gap-2 md:mb-24">
                        <StarFull className="h-12 w-12" />
                        <StarFull className="h-12 w-12" />
                        <StarFull className="h-12 w-12" />
                        <StarFull className="h-12 w-12" />
                        <StarFull className="h-12 w-12" />
                      </div>
                      <h3
                        className="mb-24 text-20 font-medium leading-[130%] md:mb-56 md:text-28 md:leading-34"
                        dangerouslySetInnerHTML={{ __html: slide.copy }}
                      ></h3>
                      <div className="text-12 font-medium opacity-40 md:text-base">{slide.quote_author}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        {props.show_featured_in && (
          <div className="-ml-16 -mr-16 mt-48 grid grid-cols-24 gap-x-16 overflow-hidden">
            <div className="col-span-24 mb-24 text-center font-serif text-14 text-gray-400">Featured in</div>
            <div className="col-span-24 lg:col-span-20 lg:col-start-3">
              <div className="relative">
                <ul className="horizontal-scroll-on-mobile w-full text-center md:flex md:justify-between">
                  {props.featured_in_logos.map((logo, i) => (
                    <li className="inline-block pr-16 lg:pr-0" key={`${logo.uuid}-${i}`}>
                      <Image src={logo.permalink} alt={logo.alt || "Partner logo"} width="124" height="40" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </Container>
    </SpacingContainer>
  )
}

export default QuoteSlider
